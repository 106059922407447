<template>
  <div class="modal modal-center" id="AddTaskPresent">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <div>
          <h3>
            Add Task Preset
          </h3>
          <div class="sub-title">
            Automatically set tasks when an event occurs
          </div>
        </div>
        <img src="@/assets/icons/icon-close.svg" class="cursor-pointer" @click="$emit('close')" alt="Close">
      </div>
      <div class="modal-body">
        <div class="text-center mt-4" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0" id="task-info" v-show="ui.loading === false">
          <div class="col-7 mt-3">
            <div class="form-group full-width">
              <label for="fieldTriggerEvent">
                Trigger Event
              </label>
              <multiselect v-model="event"
                           track-by="value"
                           label="label"
                           :options="events"
                           :searchable="false"
                           :close-on-select="true"
                           :show-labels="false"
                           :tabindex="-1"
                           id="fieldTriggerEvent"
                           placeholder="10"
                           class="pagingSelect"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="form-group full-width">
              <label for="fieldTaskName">
                Task Name
              </label>
              <input type="text" class="form-control" placeholder="Enter Task Name" id="fieldTaskName">
            </div>
          </div>
          <div>
            <draggable v-model="subtaskList">
              <transition-group>
                <div v-for="element in subtaskList" :key="element.id" class="d-flex mt-3">
                  <img src="@/assets/icons/icon-draggable.svg"
                       alt="Draggable"
                       class="cursor-pointer" content="Draggable" v-tippy="{placement: 'bottom-center',}">
                  <div class="input-icon full-width">
                    <img src="@/assets/icons/icon-delete.svg" alt="Delete task" class="cursor-pointer" @click="removeSubtask(element.id)">
                   <div class="form-group full-width">
                     <input type="text" class="form-control" v-model="element.title">
                   </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
          <div class="d-flex mt-3 align-items-center cursor-pointer subtask" @click="addSubtask">
            <img src="@/assets/icons/icon-add blue.svg" alt="Add">
            <span>
              Add Sub Task
            </span>
          </div>

          <div class="d-flex mt-3">
            <div class="check-btn" :class="{active: formData.assigner === 'mlo'}" @click="setAssigner('mlo')">
              MLO
            </div>
            <div class="check-btn" :class="{active: formData.assigner === 'processor'}" @click="setAssigner('processor')">
              Processor
            </div>
            <div class="check-btn" :class="{active: formData.assigner === 'manager'}" @click="setAssigner('manager')">
              Manager
            </div>
            <div class="check-btn" :class="{active: formData.assigner === 'admin'}" @click="setAssigner('admin')">
              Admin
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button class="btn btn-primary" @click="saveTask">
          <span v-if="ui.saving === true"
                class="spinner-border spinner-border-sm text-light"
                role="status" aria-hidden="true">
          </span>
          <span class="text-end" v-else>
            Add Task Preset
          </span>
        </button>
        <button class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import draggable from 'vuedraggable';
import PerfectScrollbar from 'perfect-scrollbar'

export default {
  name: 'AddTaskPreset',
  components: { Multiselect, draggable },
  data() {
    return {
      event: '',
      events: [],
      formData: {
        assigner: 'mlo'
      },
      ui: {
        loading: false,
        saving: false,
        ps: null
      },
      subtaskList: [],
      subtaskCount: 0
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() => {
        this.ui.loading = false;
      }, 1000);
    },
    setAssigner(val) {
      this.formData.assigner = val;
    },
    addSubtask() {
      this.subtaskCount++;
      this.subtaskList.push({id: this.subtaskCount, title: ''})
    },
    removeSubtask(id) {
      this.subtaskCount--;
      this.subtaskList = this.subtaskList.filter((el) => {
        return el.id !== id;
      });
    },
    saveTask() {
      this.ui.saving = true;
      setTimeout(() => {
        this.ui.saving = false;
      }, 1000)
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    const scrollbar = document.getElementById('task-info');
    this.ui.ps = new PerfectScrollbar(scrollbar);
  }
}
</script>

<style lang="scss" scoped>
#AddTaskPresent {
  .modal-block {
    height: fit-content;
    margin: 50px auto;
    .modal-footer {
      bottom: unset;
    }
    .modal-body {
      padding: 100px 30px 40px 30px;
    }
  }
}

#task-info {
  height: 500px;
  position: relative;
  overflow: hidden;
}

.subtask {
  span {
    color: rgba(0,0,0, 0.46);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 12px;
  }
}

.input-icon {
  width: 100%;
  input {
    width: 100%;
  }
}
</style>
