<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Task Presets
        </h3>
        <span class="subtitle">
          Create automated tasks for users
        </span>
      </div>
      <div class="page-header-buttons">
        <button class="btn btn-primary" @click="modals.addManuallyTrigger = true">
          Trigger Event
        </button>
        <button class="btn bg-green ms-2" @click="modals.addTaskPreset = true">
          Add Task Presets
        </button>
      </div>
    </div>
    <div class="page-content">
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>Auto Assigned Tasks</th>
              <th>Trigger Event</th>
              <th>Assignee Type</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <add-task-preset v-if="modals.addTaskPreset === true"
                       @close="modals.addTaskPreset = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <add-manually-trigger v-if="modals.addManuallyTrigger === true"
                            @close="modals.addManuallyTrigger = false" />
    </transition>


  </div>
</template>

<script>
import AddTaskPreset from "./modals/AddTaskPreset";
import AddManuallyTrigger from "./modals/AddManuallyTrigger";
export default {
  name: 'TaskPresets',
  components: {AddManuallyTrigger, AddTaskPreset },
  data() {
    return {
      modals: {
        addTaskPreset: false,
        addManuallyTrigger: false
      }
    }
  }
}
</script>
